import { Suspense, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import LanguageContext from "../../context/LanguageContext";
import ScrollTop from "../ScrollTop/ScrollTop";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import Page404 from "../404/404";
import MainPage from "../Pages";
import AboutPage from "../Pages/About";
import ContactsPage from "../Pages/ContactsPage";
import Productions from "../Pages/Productions";
import ProductsPage from "../Pages/ProductsPage";

import "animate.css/animate.min.css";
import Aos from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/Buttons.scss";
import "../../styles/Forms.scss";
import "../../styles/Various.scss";
import "../../styles/_main.scss";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "ru"
  );

  return (
    <Router>
      <Suspense fallback="">
        <LanguageContext.Provider value={[language, setLanguage]}>
          <Header />
          <main buildTime={Date.now()}>
            <ScrollTop>
              <Routes>
                <Route exact path="/" element={<MainPage />} />
                <Route exact path="/about" element={<AboutPage />} />
                <Route exact path="/products" element={<ProductsPage />} />
                <Route exact path="/productions" element={<Productions />} />
                <Route exact path="/contacts" element={<ContactsPage />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </ScrollTop>
          </main>
          <ScrollToTop smooth top="400" color="#291258" />
          <Footer />
        </LanguageContext.Provider>
      </Suspense>
    </Router>
  );
}

export default App;
