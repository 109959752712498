import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper';

import { Navigation } from "swiper";

import polotno from '../../resources/img/Productions/Полотно.jpg'
import model from '../../resources/img/Productions/Создание моделей.jpg'
import pechatniy from '../../resources/img/Productions/печатный цех.jpg'
import shveyniy from '../../resources/img/Productions/Швейный цех.jpg'

import "swiper/css";
import "swiper/css/navigation";
import './Productions.scss'

export default function Productions() {
    const [t] = useTranslation()
    SwiperCore.use([Autoplay])

    return (
        <section className='productions'>
            <Swiper
                loop={true}
                autoplay={{ delay: 4000 }}
                navigation={true}
                modules={[Navigation]}
                className='productions__header'
            >
                <SwiperSlide className='swiper_img1'>
                    <div className='productions__overlay' >
                        <Container className='text-center'>
                            <h4>
                                {t("production-title1")}
                            </h4>
                            <h5>Trend Tex</h5>
                        </Container>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper_img2'>
                    <div className='productions__overlay' >
                        <Container className='text-center'>
                            <h4>
                                {t("production-title2")}
                            </h4>
                            <h5>Trend Tex</h5>
                        </Container>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper_img3'>
                    <div className='productions__overlay' >
                        <Container className='text-center'>
                            <h4>
                                {t("production-title3")}
                            </h4>
                            <h5>Trend Tex</h5>
                        </Container>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper_img4'>
                    <div className='productions__overlay' >
                        <Container className='text-center'>
                            <h4>
                                {t("production-title4")}
                            </h4>
                            <h5>Trend Tex</h5>
                        </Container>
                    </div>
                </SwiperSlide>
            </Swiper>

            <Container className='productions__container'>
                <Row className='overflow-hidden'>
                    <Col md={5} data-aos="zoom-out">
                        <h3>
                            {t("production-title1")}
                        </h3>
                        <img src={polotno} width="100%" alt="" />
                    </Col>
                    <Col md={7} data-aos="zoom-out">
                        <p>
                            {t("production-title1.1")}
                        </p>
                    </Col>

                    <Col md={7} data-aos="zoom-out">
                        <div dangerouslySetInnerHTML={{ __html: t('production-title2.2') }}></div>
                    </Col>
                    <Col md={5} id="first" data-aos="zoom-out">
                        <h3>
                            {t("production-title2")}
                        </h3>
                        <img src={model} width="100%" alt="" />
                    </Col>

                    <Col md={5} id="second" data-aos="zoom-out">
                        <h3>
                            {t("production-title3")}
                        </h3>
                        <img src={pechatniy} width="100%" alt="" />
                    </Col>
                    <Col md={7} data-aos="zoom-out">
                        <div dangerouslySetInnerHTML={{ __html: t('production-title3.3') }}></div>
                    </Col>

                    <Col md={7} data-aos="zoom-out">
                        <p>
                            {t("production-title4.4")}
                        </p>
                    </Col>
                    <Col md={5} id="third" data-aos="zoom-out">
                        <h3>
                            {t("production-title4")}
                        </h3>
                        <img src={shveyniy} width="100%" alt="" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
