import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import icon1 from "../../resources/img/Main/icon1.png"
import icon2 from "../../resources/img/Main/icon2.png"
import icon3 from "../../resources/img/Main/icon3.png"
import employee from "../../resources/img/Main/employee.png"
import partner from "../../resources/img/Main/partner.png"
import sew from "../../resources/img/Main/sew.png"

import "./ServiceSection.scss";
import Tabs from '../Tab/Tab';
import { HashLink } from 'react-router-hash-link';

function ServiceSection() {
    const { t } = useTranslation()

    return (
        <section className='servicesection py'>
            <Container className='my-5'>

                <h3 className='servicesection__sup'>
                    {t("work-title")}
                </h3>
                <p className='servicesection__sub'>
                {t("work-text")}
                </p>
                <Row className='justify-content-center overflow-hidden'>
                    <Col md={8} lg={4}>
                        <div className='servicesection__box service-img1'>
                            <div data-aos="fade-right" className="servicesection__box-text">
                                <img src={icon1} alt="" />
                                <h3> {t("work1")}</h3>
                                <p>
                                {t("work1.1")}
                                </p>
                                <HashLink to="/productions#first" className="button button-fourth">{t("more")}</HashLink>
                            </div>
                        </div>
                    </Col>

                    <Col md={8} lg={4}>
                        <div className='servicesection__box service-img2'>
                            <div data-aos="fade-up" className="servicesection__box-text">
                                <img src={icon2} alt="" />
                                <h3> {t("work2")}</h3>
                                <p>
                                {t("work2.2")}
                                </p>
                                <HashLink to="/productions#second" className="button button-fourth">{t("more")}</HashLink>
                            </div>
                        </div>
                    </Col>

                    <Col md={8} lg={4}>
                        <div className='servicesection__box service-img3'>
                            <div data-aos="fade-left" className="servicesection__box-text">
                                <img src={icon3} alt="" />
                                <h3> {t("work3")}</h3>
                                <p>
                                {t("work3.3")}
                                </p>
                                <HashLink to="/productions#third" className="button button-fourth">{t("more")}</HashLink>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>

            <div className="servicesection-tab">
                <div className='servicesection-tab-layout'>
                    <Tabs />
                </div>
            </div>

            <div className="servicesection-counter">
                <div className='servicesection-counter-layout'>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <img src={icon3} width="70" alt="icon" />
                                <CountUp
                                    end={6}
                                    duration={2.75}
                                    useEasing={true}
                                    useGrouping={true}
                                    separator=" "
                                    suffix={t("counter1")}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <h6>{t("counter1.1")}</h6>
                            </Col>
                            <Col md={3}>
                                <img src={employee} width="70" alt="icon" />
                                <CountUp
                                    end={1000}
                                    duration={2.75}
                                    useEasing={true}
                                    useGrouping={true}
                                    separator=" "
                                    suffix={t("+")}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <h6>{t("counter2")}</h6>
                            </Col>
                            <Col md={3}>
                                <img src={partner} width="70" alt="icon" />
                                <CountUp
                                    end={30}
                                    duration={2.75}
                                    useEasing={true}
                                    useGrouping={true}
                                    separator=" "
                                    suffix={t("+")}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <h6>{t("counter3")}</h6>
                            </Col>
                            <Col md={3}>
                                <img src={sew} width="70" alt="icon" />
                                <CountUp
                                    end={500}
                                    duration={2.75}
                                    useEasing={true}
                                    useGrouping={true}
                                    separator=" "
                                    suffix={t("counter4.1")}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <h6>{t("counter4")}</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </section>
    )
}

export default ServiceSection