import AboutSection from "../Sections/AboutSection";
import AdvantageSection from "../Sections/AdvantageSection";
import IntroSection from "../Sections/IntoSection";
import PartnersSection from "../Sections/PartnersSection";
import ServiceSection from "../Sections/ServiceSection";


export default function MainPage() {

    return (
        <>
            <IntroSection />
            <AdvantageSection />
            <AboutSection />
            <ServiceSection />
            <PartnersSection />
        </>
    )
}