import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./AboutSection.scss";

function AboutSection() {
    const { t } = useTranslation()

    return (
        <section className="aboutsection">
            <div className="aboutsection-overlay">
                <Container>

                    <p>
                        {t("aboutsection")}
                    </p>

                </Container>
            </div>
        </section>
    )
}

export default AboutSection;