import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';

import Fancybox from '../ImageViewer/Fancybox'
import img1 from '../../resources/img/ProductsPage/Detskiy/3F4A0902.jpg'
import img2 from '../../resources/img/ProductsPage/Detskiy/3F4A0922.jpg'
import img3 from '../../resources/img/ProductsPage/Detskiy/3F4A0937.jpg'
import img4 from '../../resources/img/ProductsPage/Detskiy/3F4A0958.jpg'
import img5 from '../../resources/img/ProductsPage/Detskiy/3F4A0966.jpg'
import img6 from '../../resources/img/ProductsPage/Detskiy/3F4A0979.jpg'
import img7 from '../../resources/img/ProductsPage/Detskiy/3F4A0993.jpg'
import img8 from '../../resources/img/ProductsPage/Detskiy/3F4A1011.jpg'
import img9 from '../../resources/img/ProductsPage/Detskiy/3F4A1022.jpg'
import img10 from '../../resources/img/ProductsPage/Detskiy/3F4A1046.jpg'
import img11 from '../../resources/img/ProductsPage/Detskiy/3F4A1056.jpg'
import img12 from '../../resources/img/ProductsPage/Detskiy/3F4A1122.jpg'

import './ProductsPage.scss'

export default function ProductsPage() {
    const [t] = useTranslation()

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <section className='products'>
            <div className='products__header'>
                <div className='products__overlay' >
                    <Container className='text-center'>
                        <h1>
                            {t("productpage")}
                        </h1>

                        <p>Trend Tex</p>
                    </Container>
                </div>
            </div>

            <Container>
                <h3>
                {t("productcollect")}
                </h3>

                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList scrollButtons="auto" centered onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label={t("productChild")} value="1" />
                                <Tab label={t("productMen")} disabled value="2" />
                                <Tab label={t("productWomen")} disabled value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Fancybox options={{ infinite: false }}>

                                <Row>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img1}
                                        >
                                            <img src={img1} width="400" alt="?" />
                                        </a>
                                    </Col>

                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img2}
                                        >
                                            <img src={img2} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img3}
                                        >
                                            <img src={img3} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img4}
                                        >
                                            <img src={img4} width="400" alt="?" />
                                        </a>
                                    </Col>

                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img5}
                                        >
                                            <img src={img5} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img6}
                                        >
                                            <img src={img6} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img7}
                                        >
                                            <img src={img7} width="400" alt="?" />
                                        </a>
                                    </Col>

                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img8}
                                        >
                                            <img src={img8} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img9}
                                        >
                                            <img src={img9} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img10}
                                        >
                                            <img src={img10} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img11}
                                        >
                                            <img src={img11} width="400" alt="?" />
                                        </a>
                                    </Col>
                                    <Col md={4}>
                                        <a
                                            data-fancybox="gallery"
                                            data-src={img12}
                                        >
                                            <img src={img12} width="400" alt="?" />
                                        </a>
                                    </Col>

                                </Row>
                            </Fancybox>
                        </TabPanel>

                        <TabPanel value="2">
                            <Row>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={img2}
                                    >
                                        <img src={img2} width="400" alt="?" />
                                    </a>
                                </Col>

                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={img2}
                                    >
                                        <img src={img2} width="400" alt="?" />
                                    </a>
                                </Col>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={img2}
                                    >
                                        <img src={img2} width="400" alt="?" />
                                    </a>
                                </Col>
                            </Row>
                        </TabPanel>

                        <TabPanel value="3">
                            <Row>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={img3}
                                    >
                                        <img src={img3} width="400" alt="?" />
                                    </a>
                                </Col>

                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={img3}
                                    >
                                        <img src={img3} width="400" alt="?" />
                                    </a>
                                </Col>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={img3}
                                    >
                                        <img src={img3} width="400" alt="?" />
                                    </a>
                                </Col>
                            </Row>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </section>
    )
}
