import InstagramIcon from "@mui/icons-material/Instagram";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../resources/img/logo.png";
import "./Footer.scss";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__overlay">
        <Container>
          <Row>
            <Col md={4}>
              <img src={logo} alt="trend tex" />

              <p>{t("footer-title")}</p>
            </Col>
            <Col md={4}>
              <h3>{t("company")}</h3>

              <div className="footer__link">
                <Link to="/about">{t("О нас")}</Link>
                <Link to="/products">{t("Продукция")}</Link>
                <Link to="/productions">{t("Производство")}</Link>
                <Link to="contacts">{t("Контакты")}</Link>
              </div>
            </Col>
            <Col md={4}>
              <h3>{t("contact-us")}</h3>

              <div className="footer__link">
                <a href="tel:+998712485533">+99871-248-55-33</a>
                <a href="tel:+998712487272">+99871-248-72-72</a>
              </div>

              <div className="footer__link">
                <a
                  href="http://instagram.com/trend_tex?utm_source=qr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </div>

              <h3 className="mt-5">{t("address")}</h3>

              <div className="footer__link">
                <p>{t("address-name")}</p>
              </div>
            </Col>

            <Col className="footer__bottom">
              <span>TREND TEX {new Date().getFullYear()}</span>

              <a href="https://wtma.uz/">Website developer wtma.uz</a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
