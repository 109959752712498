import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import img from '../../resources/img/404.png'

export default function Page404() {
    const { t } = useTranslation()
    return (
        <section className='document py'>
            <Container>
                <Row className="align-items-center flex-md-row flex-column-reverse ">
                    <Col md={6}>
                        <h1>{t("404title")}</h1>
                        <p>{t("404text")}</p>
                        <button className='button button_orange w-auto'>
                            <Link to="/">
                            {t("sendbtn")}
                            </Link>
                        </button>
                    </Col>
                    <Col md={6}>
                        <img className='document__image' src={img} alt="empty" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}