import { useTranslation } from 'react-i18next';
import MyForm from '../Form/MyForm';

import { Col, Container } from 'react-bootstrap'

import './ContactsPage.scss';

function ContactsPage() {
    const { t } = useTranslation()

    return (
        <section className='contacts'>
            <div className='contacts__header'>
                <div className='contacts__overlay'>
                    <Container className='text-center'>
                        <h1>
                            {t("contacts")}
                        </h1>
                        <span>{t("contacts-callback")}</span>
                    </Container>
                </div>
            </div>

            <div className="contacts__contact">
                <div className='contacts__contact-overlay'>
                    <Container>
                        <h5 className='text-center text-white'>{t("Контакты")}</h5>
                        <h4 className='text-center text-white fw-bold mb-5 fs-2'>
                            {t("contacts-sub")}
                        </h4>
                        <Col md={10} className="m-auto">
                            <MyForm rightClass={"contacts-form-info"} class={"form-info-inner"} />
                        </Col>
                    </Container>
                </div>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.020520076656!2d69.63395473871468!3d40.91529595474928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae331d6331f8e3%3A0xf7a0657b4715250d!2sTrend%20Tex!5e0!3m2!1sru!2s!4v1660647876909!5m2!1sru!2s" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>
    )
}

export default ContactsPage