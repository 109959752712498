import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check';

import SvgMap from '../SvgMap/SvgMap'
import MyForm from '../Form/MyForm';
import { HashLink } from 'react-router-hash-link';

import './About.scss'

export default function AboutPage() {
    const [t] = useTranslation()

    return (
        <section className='about'>

            <div className='about__header'>
                <div className='about__overlay' >
                    <Container>
                        <div dangerouslySetInnerHTML={{ __html: t('about-title') }}></div>

                        <div dangerouslySetInnerHTML={{ __html: t('intro-text') }}></div>

                        <HashLink to="/about#story" className='button button-fourth'>{t("more")}</HashLink>
                    </Container>
                </div>
            </div>

            <div className='about__story'>
                <Row className='g-0'>
                    <Col md={6} className="about__story-left" />

                    <Col id="story" md={6} className="about__story-right">
                        <div className='about__story-right-box'>
                            <h2 className='about__story-sup'>
                                {t("about-story")}
                            </h2>
                            <h2 className='about__story-sub'>
                                {t("about-story-sub")}
                            </h2>
                            <p>
                                {t("about-parag")}
                            </p>

                            <ul>
                                <li>
                                    <CheckIcon />{t("about-li1")}
                                </li>
                                <li>
                                    <CheckIcon />{t("about-li2")}
                                </li>
                                <li>
                                    <CheckIcon />{t("about-li3")}
                                </li>
                                <li>
                                    <CheckIcon />{t("about-li4")}
                                </li>
                            </ul>

                            <p className='mb-5'>
                                {t("about-parag2")}
                            </p>

                            <HashLink to="/about#callback" className='button button-fourth'>{t("callback")}</HashLink>
                        </div>
                    </Col>
                </Row>
            </div>

            <Container>
                <div className='ratio ratio-16x9 my-4'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/wn38AiB_-_Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Container>

            <div className='about__facilities'>

                <Row className='g-0 about__facilities-img'>
                    <Col md={6} />

                    <Col data-aos="zoom-in-left" md={6} className="about__facilities-text" >
                        <div>
                            <h5>
                                {t("aboutadv-title")}
                            </h5>
                            <h4>
                                {t("aboutadv-sub")}
                            </h4>

                            <p>
                                {t("aboutadv-text")}
                            </p>
                            <p>
                                {t("aboutadv-text2")}
                            </p>
                        </div>
                    </Col>

                    <Col data-aos="zoom-in-right" md={6} className="about__facilities-text" >
                        <div >
                            <h5>
                                {t("aboutexp-title")}
                            </h5>
                            <h4>
                                {t("aboutexp-sub")}
                            </h4>

                            <p>
                                {t("aboutexp-text")}
                            </p>
                            <p>
                                {t("aboutexp-text2")}
                            </p>

                            <p>
                                {t("aboutexp-text3")}
                            </p>
                            <p>
                                {t("aboutexp-text4")}
                            </p>
                        </div>
                    </Col>
                    <Col md={6} />
                </Row>

                <Container>
                    <SvgMap />
                </Container>

                <div className="about__contact" id="callback">
                    <div className='about__contact-overlay'>
                        <Container>
                            <h5 className='text-center text-white'>{t("Контакты")}</h5>
                            <h4 className='text-center text-white fw-bold mb-5 fs-2'>
                                {t("contact-sub")}
                            </h4>
                            <MyForm class={"my-form-info"} />
                        </Container>
                    </div>
                </div>
            </div>
        </section >
    )
}
