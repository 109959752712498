import axios from 'axios'

class ApplicationService {
    url = process.env.REACT_APP_API_URL
    createApplication = (phone, email, name) => {
        const form = new FormData()
        form.append('phone', phone)
        form.append('email', email)
        form.append('name', name)
        return axios.post(this.url, form)
    }
}

export default ApplicationService