import { useTranslation } from 'react-i18next'

function useMenu() {
    const { t } = useTranslation()

    const getLi = [
        {
            link: '/',
            title: t("Главная")
        },
        {
            link: '/about',
            title: t("О нас")
        },
        {
            link: '/products',
            title: t("Продукция")
        },
        {
            link: '/productions',
            title: t("Производство")
        },
        {
            link: '/contacts',
            title: t("Контакты")
        },
    ]

    const getMobLi = [
        {
            link: '/',
            title: t("Главная")
        },
        {
            link: '/about',
            title: t("О нас")
        },
        {
            link: '/products',
            title: t("Продукция")
        },
        {
            link: '/productions',
            title: t("Производство")
        },
        {
            link: '/contacts',
            title: t("Контакты")
        },
    ]

    return { getLi, getMobLi }
}

export default useMenu;