import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Fancybox from '../ImageViewer/Fancybox'
import kid1 from '../../resources/img/Main-tab/kid1.jpg'
import kid2 from '../../resources/img/Main-tab/kid2.jpg'
import kid3 from '../../resources/img/Main-tab/kid3.jpg'

import women1 from '../../resources/img/Main-tab/women1.jpg'
import women2 from '../../resources/img/Main-tab/women2.jpg'
import women3 from '../../resources/img/Main-tab/women3.jpg'

import man1 from '../../resources/img/Main-tab/man1.jpg'
import man2 from '../../resources/img/Main-tab/man2.jpg'
import man3 from '../../resources/img/Main-tab/man3.jpg'


import './Tab.scss'
import { t } from 'i18next';

function Tabs() {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="tabs__component">
            <Link to="/products">
                {t("product")}
            </Link>
            <h4>
                {t("productsub")}
            </h4>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                        <TabList scrollButtons="auto" centered onChange={handleChange} aria-label="scrollable auto  tabs example">
                            <Tab label={t("productChild")} value="1" />
                            <Tab label={t("productMen")} value="2" />
                            <Tab label={t("productWomen")} value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Fancybox options={{ infinite: false }}>
                            <Row>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={kid1}
                                    >
                                        <img src={kid1} width="400" alt="?" />
                                    </a>
                                </Col>

                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={kid2}
                                    >
                                        <img src={kid2} width="400" alt="?" />
                                    </a>
                                </Col>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={kid3}
                                    >
                                        <img src={kid3} width="400" alt="?" />
                                    </a>
                                </Col>
                            </Row>
                        </Fancybox>
                    </TabPanel>

                    <TabPanel value="2">
                        <Fancybox options={{ infinite: false }}>
                            <Row>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={man1}
                                    >
                                        <img src={man1} width="400" alt="?" />
                                    </a>
                                </Col>

                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={man2}
                                    >
                                        <img src={man2} width="400" alt="?" />
                                    </a>
                                </Col>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={man3}
                                    >
                                        <img src={man3} width="400" alt="?" />
                                    </a>
                                </Col>
                            </Row>
                        </Fancybox>
                    </TabPanel>

                    <TabPanel value="3">
                        <Fancybox options={{ infinite: false }}>
                            <Row>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={women1}
                                    >
                                        <img src={women1} width="400" alt="?" />
                                    </a>
                                </Col>

                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={women2}
                                    >
                                        <img src={women2} width="400" alt="?" />
                                    </a>
                                </Col>
                                <Col md={4}>
                                    <a
                                        data-fancybox="gallery"
                                        data-src={women3}
                                    >
                                        <img src={women3} width="400" alt="?" />
                                    </a>
                                </Col>
                            </Row>
                        </Fancybox>
                    </TabPanel>

                </TabContext>
            </Box>
        </div>
    )
}

export default Tabs