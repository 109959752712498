import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import videoTrend from "../../resources/video/dji-0602_pCFZx2Jv.mp4";

import "./IntroSection.scss";

function IntroSection() {
  const { t } = useTranslation();

  return (
    <section className="intro">
      <video className="videoTag" autoPlay loop muted>
        <source src={videoTrend} type="video/mp4" />
      </video>
      <div className="intro__header">
        <div className="intro__overlay">
          <Container>
            <div dangerouslySetInnerHTML={{ __html: t("intro-title") }}></div>

            <div dangerouslySetInnerHTML={{ __html: t("intro-text") }}></div>

            <Link to="/about" className="button button-fourth">
              {t("О нас")}
            </Link>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
