import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

import zolla from '../../resources/img/Partners/zolla.png'
import gj from '../../resources/img/Partners/gj.png'
import Lotto from '../../resources/img/Partners/Lotto.png'
import Ostin from '../../resources/img/Partners/Ostin.png'

import "./PartnersSection.scss";


function PartnersSection() {
    const { t } = useTranslation()
    return (
        <section className='partnerssection'>
            <Container>
                <h2 className='line-title mb-4 fs-1'>{t("ourpartners")}</h2>
                <Row>
                    <Col md={3} className="partnerssection__box">
                        <img src={zolla} width="100%" alt="" />
                    </Col>
                    <Col md={3} className="partnerssection__box">
                        <img src={gj} width="100%" alt="" />
                    </Col>
                    <Col md={3} className="partnerssection__box">
                        <img src={Lotto} width="100%" alt="" />
                    </Col>
                    <Col md={3} className="partnerssection__box">
                        <img src={Ostin} width="100%" alt="" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PartnersSection