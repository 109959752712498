import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";

import first from '../../resources/img/Main/number1.jpg'
import second from '../../resources/img/Main/number2.jpg'
import third from '../../resources/img/Main/number3.jpg'

import './AdvantageSection.scss'

function AdvantageSection() {
    const { t } = useTranslation()

    return (
        <section className='advantage py'>
            <Container>
                <div dangerouslySetInnerHTML={{ __html: t('advantage-title') }}></div>
                <p>{t("advantage-text")}</p>

                <Row className='overflow-hidden'>
                    <Col md={4} data-aos="fade-up-right" className='p-3'>
                        <div className="advantage__box">
                            <div className="advantage__box-img">
                                <img src={first} alt="1" />
                            </div>
                            <span>
                                01
                            </span>
                        </div>

                        <p className='advantage__sub'>{t("advantage1")}</p>
                    </Col>

                    <Col  data-aos="fade-up" md={4} className='p-3'>
                        <div className="advantage__box">
                            <div className="advantage__box-img">
                                <img src={second} alt="1" />
                            </div>
                            <span>
                                02
                            </span>
                        </div>

                        <p className='advantage__sub'>{t("advantage2")} </p>
                    </Col>

                    <Col md={4} data-aos="fade-up-left" className='p-3'>
                        <div className="advantage__box">
                            <div className="advantage__box-img">
                                <img src={third} alt="1" />
                            </div>
                            <span>
                                03
                            </span>
                        </div>

                        <p className='advantage__sub'>{t("advantage3")}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AdvantageSection;